import { Grid, IconButton, Typography, Box } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useEffect, useState } from 'react'
import RewardStack from '../components/rewards/RewardStack'
import Loading from '../components/Loading'
import { loyaltyService } from '../di/diOmega'
import NoInProgress from '../components/rewards/NoInProgress'

// Page loads:
// fetch array of rewards information from

interface IVisitRewardCard {
    id: string
    businessName: string
    visitsRequired: number
    visitedCount: number
    latestVisitAnimate: boolean
    rewardText: string
}

export default function Page () {
    const [loading, setLoading] = useState(true)
	const [nothing, setNothing] = useState(true)
    const [visitRewardCards, setVisitRewardCards] = useState<
        IVisitRewardCard[]
    >([])

    const [businessName, setBusinessName] = useState<string>('')
    const [earned, setEarned] = useState<number>(0)
    const [visitsToGo, setVisitsToGo] = useState<number>(0)
    const [page, setPage] = useState<number>(0)
    const [rewardVisitCardId, setRewardVisitCardId] = useState<string>('')

    const [visitsRequired, setVisitsRequired] = useState<number>(0)
    const [visitedCount, setVisitedCount] = useState<number>(0)

    const [rewardLabel, setRewardLabel] = useState<string>('')

    const [prevDisabled, setPrevDisabled] = useState<boolean>(true)
    const [nextDisabled, setNextDisabled] = useState<boolean>(false)

    const [latestVisitAnimate, setLatestVisitAnimate] = useState<boolean>(false)

    const updatePage = (
        index: number,
        visitRewardCards: IVisitRewardCard[]
    ) => {
        if (index < 0 || index >= visitRewardCards.length) {
            return
        }
        setPage(index)

        // Set the arrows to enabled/disabled which switches between pages
        index === 0 ? setPrevDisabled(true) : setPrevDisabled(false)
        index === visitRewardCards.length - 1
            ? setNextDisabled(true)
            : setNextDisabled(false)

        setEarned(visitRewardCards[index].visitedCount)
        setVisitsToGo(
            visitRewardCards[index].visitsRequired -
                visitRewardCards[index].visitedCount
        )
        setVisitsRequired(visitRewardCards[index].visitsRequired)
        setVisitedCount(visitRewardCards[index].visitedCount)
        setRewardLabel(visitRewardCards[index].rewardText)
        setLatestVisitAnimate(visitRewardCards[index].latestVisitAnimate)
        setRewardVisitCardId(visitRewardCards[index].id)
        setBusinessName(visitRewardCards[index].businessName)
    }

    const clickPrevBtn = () => {
        updatePage(page - 1, visitRewardCards)
    }

    const clickNextBtn = () => {
        updatePage(page + 1, visitRewardCards)
    }

    // -- WINDOW dimensions --
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window
        return {
            width,
            height,
        }
    }

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    )

    useEffect(() => {
        const fetchData = async () => {
            const visitRewardCards = await loyaltyService.getAll()
            setVisitRewardCards(visitRewardCards)
            updatePage(0, visitRewardCards)
			if (visitRewardCards.length > 0) {
				setNothing(false)
			}
            setLoading(false)
        }
        fetchData()

        function handleResize () {
            setWindowDimensions(getWindowDimensions())
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    // -- END WINDOW dimensions --

    // TODO -- Setup the modal
    // -- Need to pass the ModalIsOpen into the RewardStack

    return (
        <Loading loading={loading}>
            <NoInProgress nothing={nothing}>
                <Box
                    p={0}
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    sx={{ height: { xs: '100vh', sm: '100vh' } }}
                >
                    <Box p={3} textAlign='center'>
                        <Typography
                            sx={{
                                fontSize: { xs: '30px', sm: '40px' },
                                fontWeight: '700',
                            }}
                        >
                            {businessName}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            display='flex'
                            alignItems='center'
                            sx={{ fontSize: { xs: '20px', sm: '28px' } }}
                        >
                            You have earned {earned}&nbsp;
                            <CheckCircleIcon
                                sx={{
                                    color: 'primary.dark',
                                    fontSize: { xs: '30px', sm: '38px' },
                                }}
                            />
                        </Typography>
                    </Box>
                    <Box pb={5} textAlign='center'>
                        <Typography
                            sx={{ fontSize: { xs: '20px', sm: '28px' } }}
                        >
                            Just {visitsToGo} more to go!
                        </Typography>
                    </Box>
                    <Grid
                        container
                        sx={{
                            backgroundColor: '#D0DEF7',
                            borderTopLeftRadius: '300px 150px',
                            borderTopRightRadius: '300px 150px',
                        }}
                        flex='1'
                        flexDirection='column'
                    >
                        <Grid
                            item
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            py={7}
                        >
                            <Grid item>
                                <IconButton
                                    aria-label='Prev'
                                    onClick={clickPrevBtn}
                                    disabled={prevDisabled}
                                >
                                    <ArrowBackIosNewIcon
                                        sx={{
                                            color: prevDisabled
                                                ? 'grey'
                                                : 'black',
                                            fontSize: {
                                                xs: '40px',
                                                sm: '60px',
                                            },
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item textAlign='center' px={2}>
                                <Typography
                                    sx={{
                                        fontSize: { xs: '24px', sm: '40px' },
                                    }}
                                    color='primary.dark'
                                    fontWeight='bold'
                                >
                                    {rewardLabel}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    aria-label='Next'
                                    onClick={clickNextBtn}
                                    disabled={nextDisabled}
                                >
                                    <ArrowForwardIosIcon
                                        sx={{
                                            color: nextDisabled
                                                ? 'grey'
                                                : 'black',
                                            fontSize: {
                                                xs: '40px',
                                                sm: '60px',
                                            },
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item flex='1'>
                            <RewardStack
                                rewardVisitCardId={rewardVisitCardId}
                                page={page}
                                windowDimensions={windowDimensions}
                                visitsRequired={visitsRequired}
                                visitedCount={visitedCount}
                                latestVisitAnimate={latestVisitAnimate}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </NoInProgress>
        </Loading>
    )
}
