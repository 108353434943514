import { Container, Typography, Box, Slider, Button } from '@mui/material'
import QRCode from 'react-qr-code'
import Modal from 'react-modal'
import { useEffect, useState } from 'react'
import { loyaltyService } from '../di/diOmega'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/Loading'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

export default function QRReward () {
  const navigate = useNavigate()

  const [redeemButtonVisible, setRedeemButtonVisible] = useState(true)
  const [loading, setLoading] = useState(true)
  const [rewardLabel, setRewardLabel] = useState<string>("")
  const [businessName, setBusinessName] = useState<string>("")
  const [redeemMsg, setRedeemMsg] = useState("Perform at store infront of clerk to verify you had this reward:")

  // 1. get parameter id
  // 2. is id redeemable? no - go back
  // 3. query loyaltyservice for reward from id
  // 4. show reward
  // 5. Tap on button ask one last time are you sure you want to redeem.
  // 6. Show message that it has been successfully redeemed

  // 1. get parameter id
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const idValue = urlParams.get('id')

  useEffect(() => {
    const fetchData = async () => {
      if (idValue === null) {
        navigate('/login')
      }

      // 2. is id redeemable? no - go back
      if (!await loyaltyService.isRedeemable(idValue!)) {
        navigate('/login')
      }

      // 3. query loyaltyservce for reward from id
      const card = await loyaltyService.get(idValue!)
      setRewardLabel(card.rewardText)
      setBusinessName(card.businessName)

      // 4. show the reward
      // TODO change the react to a loading and set values.
      setLoading(false)
    }
    fetchData()
  }, [])

  function popupRedeemAlert() {
    const options = {
      title: 'Redeem Reward - ' + businessName,
      message: 'Please perform infront of store clerk so they can verify.',
      buttons: [
        {
          label: 'Redeem Reward',
          onClick: () => redeem()
        },
        {
          label: 'Cancel'
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypress: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name"
    };
    
    confirmAlert(options);
  }

  async function redeem() {
      setLoading(true)
      let didRedeem = await loyaltyService.redeem(idValue!)
      if (didRedeem) {
        setRedeemMsg("Reward successfully redeemed!")
        setRedeemButtonVisible(false)
      } else {
        setRedeemMsg("Unable to redeem reward. Try again.")
      }
      setLoading(false)
  }

  function navigateBack() {
    navigate('/loyaltyrewards')
  }

  return (
    <Loading loading={loading}>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          backgroundColor: 'primary.dark',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          p: 0
        }}
      >          
        <Container
          sx={{
            width: 'auto',
            padding: '50px !important',
            maxWidth: '90vw',
            minWidth: '300px',
            display: 'flex',
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: '30px'
          }}
        >
          <Button sx={{
            marginLeft: "auto",
            marginRight: 0
          }}
          size='large' variant='outlined' onClick={navigateBack}>
            Back
          </Button>

          <Typography variant='h5' p={2} fontWeight={700}>
            {businessName}
          </Typography>
          <Typography variant='h5' p={2} fontWeight={700}>
            {redeemMsg}
          </Typography>
          <Typography variant='h5' p={2} fontWeight={700}>
            {rewardLabel}            
          </Typography>
          {redeemButtonVisible && <Button size='large' variant='contained' onClick={popupRedeemAlert}>
            Redeem Reward
          </Button>}
          <Box
            sx={{
              '&:before': {
                borderLeft: '1px solid blue',
                position: 'absolute',
                top: 30,
                right: 30
              }
            }}
            style={{ borderImage: 'url(./camSquare.png) 50 round' }}
          ></Box>
        </Container>
      </Box>
    </Loading>
  )
}
