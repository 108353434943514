import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import StarsIcon from "@mui/icons-material/StarRate";

export interface ISuccessDiamond {
  rewardVisitCardId: string;
  enable: boolean;
}

export default function SuccessDiamond(
  props: ISuccessDiamond
): React.ReactElement {
  const history = useNavigate();
  const diamond = (
    <StarsIcon
      sx={{
        width: { xs: "14vw", sm: "8vw", md: "7vw" },
        height: { xs: "14vw", sm: "8vw", md: "7vw" },
        color: "white",
      }}
    />
  );

  const toQrreward = () => {
    history("/qrreward?id=" + props.rewardVisitCardId);
  };

  return (
    <Grid item xs={3} md={3} sm={3} display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "17vw", sm: "9vw", md: "8vw" },
          height: { xs: "17vw", sm: "9vw", md: "8vw" },
          borderRadius: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#FEB341",
          "&:hover": {
            backgroundColor: "#FEB37A",
            opacity: [0.9, 0.8, 0.7],
          },
        }}
        onClick={toQrreward}
      >
        {props.enable ? diamond : ""}
      </Box>
    </Grid>
  );
}
