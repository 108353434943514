import {
    Avatar,
    Box,
    Button,
    Container,
    createTheme,
    CssBaseline,
    Grid,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { useLocation, useNavigate } from 'react-router-dom'
import { authService } from '../di/diOmega'
import { useEffect, useState } from 'react'
import { Header } from '../components/Common'
import { isSigningOut } from '../utils/navigation'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Copyright from '../components/Copyright'
import logoImg from '../images/logos/2-transparent.png'
import UserEntranceForm from '../components/UserEntranceForm'

const fieldStype = {
    marginTop: 3,
    marginBottom: 3,
    display: 'block',
}

const theme = createTheme()

export default function Page () {
    const navigate = useNavigate()
    const location = useLocation()
    const signingOut = isSigningOut(location)

    const [errorMessage, setErrorMessage] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    if (signingOut) {
        authService.signout()
    }

    useEffect(() => {
        const checkAuth = async () => {
            if (await authService.isAuth()) {
                if (clientId != null && clientLocationId != null) {
                    navigate(
                        '/qrlanding?cid=' + clientId + '&lid=' + clientLocationId
                    )
                } else {
                    navigate('/loyaltyrewards')
                }
            }
        }
        checkAuth()
    }, [])

    // Get parameter id
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const clientId = urlParams.get('cid')
    const clientLocationId = urlParams.get('lid')

    const handleSignin = () => {
        if (clientId != null && clientLocationId != null) {
            navigate('/login?cid=' + clientId + '&lid=' + clientLocationId)
        } else {
            navigate('/login')
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (loading) {
            return
        }
        setLoading(true)
        const data = new FormData(e.currentTarget)

        const email = data.get('email')
        const password = data.get('password')

        if (
            email == null ||
            password == null ||
            email === '' ||
            password === ''
        ) {
            setErrorMessage('Your email and password are required.')
            setLoading(false)
            return
        }

        const auth = await authService.createAccount(
            email.toString(),
            password.toString()
        )
        if (auth.success) {
            if (clientId != null && clientLocationId != null) {
                navigate(
                    '/qrlanding?cid=' + clientId + '&lid=' + clientLocationId
                )
            } else {
                navigate('/loyaltyrewards')
            }
        } else {
            setErrorMessage('Unable to create account: ' + auth.error)
            setLoading(false)
        }
    }

    const logo = (
        <Box
            component='img'
            sx={{
                height: 75,
                width: 109,
            }}
            alt='logo'
            src={logoImg}
        />
    )

    return (
        <UserEntranceForm
            onCTAButton={handleSubmit}
            ctaButtonTitle={'Continue'}
            ctaRunning={loading}
            onLeftButton={handleSignin}
            leftButtonMsg={"Already have an account?"}
            leftButtonTitle={'Log in'}
            title={'Create your account'}
            subtitle={'Begin your journey to free rewards is just a visit away!'}
            message={message}
            errorMessage={errorMessage}
            logo={logo}
        />
    )
}
