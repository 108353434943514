import { Location } from '@remix-run/router'

export function isLoginPage (location: Location) {
  return location.pathname === '/' || location.pathname === '/login' || location.pathname === '/createaccount'
}

export function isShowQRCode (location: Location) {
  return location.pathname === '/showqrcode'
}

export function isSigningOut (location: Location) {
  return location.pathname === '/login' && location.search === "?signout=1"
}