import FirebaseAuthService from "../service/auth/FirebaseAuthService";
import IAuthService from "../service/auth/IAuthService";
import FirebaseLoyaltyService from "../service/loyalty/FirebaseLoyaltyService";
import ILoyaltyService from "../service/loyalty/ILoyaltyService";

export function initializeAuthService(): IAuthService {
    return new FirebaseAuthService();
}

export function initializeLoyaltyService(): ILoyaltyService {
    return new FirebaseLoyaltyService();
}
