import React from 'react';
import logo from './logo.svg';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { deepOrange, pink } from '@mui/material/colors';

import Login from './routes/login'
import CreateAccount from './routes/createAccount'
import LoyaltyRewards from './routes/loyaltyrewards'
import QRLanding from './routes/qrlanding'
import QRReward from './routes/qrreward'
import Auth from './components/Auth';

// Firebase imports //
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseApp from './fbConfig'

const analytics = getAnalytics(firebaseApp);

const theme = createTheme({
  palette: {
    primary: pink,
    secondary: deepOrange
  }
})

function App() {
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='login' element={<Login />} />
        <Route path='createaccount' element={<CreateAccount />} />
        <Route path='loyaltyrewards' element={ <Auth><LoyaltyRewards /></Auth>} />
        <Route path='qrlanding' element={<QRLanding />} />
        <Route path='qrreward' element={<Auth><QRReward /></Auth>} />
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
