import { VisitParams } from '../../../../../shire/types/Firebase/functions/Visit'
import { RedeemParams } from '../../../../../shire/types/Firebase/functions/Redeem'
import { ClearParams } from '../../../../../shire/types/Firebase/functions/Clear'
import { MessageResult } from '../../../../../shire/types/Firebase/functions/MessageResult'
import { VisitRewardsCardsModel } from '../../../../../shire/types/Firebase/db/VisitRewardsCardsModel'
import { firebaseOnCall } from '../../../utils/firebaseCreateFunction'
import ILoyaltyService, { IVisitRewardCard } from './ILoyaltyService'
import { firebaseAuth, firebaseDb } from '../../../fbConfig'
import { collection, doc, getDoc, getDocs } from 'firebase/firestore'

export default class FirebaseLoyaltyService implements ILoyaltyService {

    async clearLatestVisitAnimate(rewardCardId: string): Promise<boolean> {
        const clearRewardCardAnimate = firebaseOnCall<ClearParams, MessageResult>(
            'clearRewardCard'
        )
        const result = await clearRewardCardAnimate({
            visitRewardsCardId: rewardCardId,
        })

        return result.success
    }

    async visitRewardCard (
        clientId: string,
        clientLocationId: string,
        latitude: number,
        longitude: number
    ) {
        const visit = firebaseOnCall<VisitParams, MessageResult>(
            'visitRewardCard'
        )
        const result = await visit({
            clientid: clientId,
            location: clientLocationId,
            latitude: latitude,
            longitude: longitude,
        })

        return result
    }

    async getAll () {
        if (firebaseAuth.currentUser == null) {
            throw new Error('User is not signed in')
        }

        let cards: IVisitRewardCard[] = []
        const querySnapshot = await getDocs(
            collection(
                firebaseDb,
                'web_users',
                firebaseAuth.currentUser.uid,
                'visit_rewards_cards'
            )
        )

        var visitRewardCardIdToClear: string = ""
        querySnapshot.forEach(doc => {
            const data = doc.data() as VisitRewardsCardsModel
            if (data.latestVisitAnimate) {
                visitRewardCardIdToClear = doc.id
            }
            cards.push({
                id: doc.id,
                businessName: data.businessName,
                visitsRequired: data.visitsRequired,
                visitedCount: data.visitedCount,
                latestVisitAnimate: data.latestVisitAnimate,
                rewardText: data.rewardText,
            })
        })

        // Clear out the animation so next time you see it, it won't be animating.
        if (visitRewardCardIdToClear !== "") {
            await this.clearLatestVisitAnimate(visitRewardCardIdToClear)
        }

        return cards
    }

    async get (rewardCardId: string) {
        if (firebaseAuth.currentUser == null) {
            throw new Error('User is not signed in')
        }
        const docRef = doc(
            firebaseDb,
            'web_users',
            firebaseAuth.currentUser.uid,
            'visit_rewards_cards',
            rewardCardId
        )
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            const data = docSnap.data() as VisitRewardsCardsModel
            return {
                id: docSnap.id,
                businessName: data.businessName,
                visitsRequired: data.visitsRequired,
                visitedCount: data.visitedCount,
                latestVisitAnimate: data.latestVisitAnimate,
                rewardText: data.rewardText,
            }
        } else {
            throw new Error('User is missing data')
        }
    }

    async isRedeemable (rewardCardId: string) {
        if (firebaseAuth.currentUser == null) {
            throw new Error('User is not signed in')
        }

        console.log("isRedeemable: rewardCardId:" + rewardCardId)

        const docRef = doc(
            firebaseDb,
            'web_users',
            firebaseAuth.currentUser.uid,
            'visit_rewards_cards',
            rewardCardId
        )
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            console.log("isRedeemable: FOUND")

            const data = docSnap.data() as VisitRewardsCardsModel
            console.log("isRedeemable: FOUND data: data.visitedCount: " + data.visitedCount + ", data.visitsRequired: " + data.visitsRequired)
            return data.visitedCount >= data.visitsRequired
        } else {
            throw new Error('User is missing data')
        }
    }

    async redeem (rewardCardId: string) {
        const redeemFunc = firebaseOnCall<RedeemParams, MessageResult>(
            'redeemRewardCard'
        )
        const result = await redeemFunc({
            visitRewardsCardId: rewardCardId,
        })

        return result.success
    }
}
