import { FirebaseError } from 'firebase/app'
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
} from 'firebase/auth'
import { firebaseAuth, firebaseDb } from '../../../fbConfig'
import IAuthService, { IResult } from './IAuthService'
import { MessageResult } from '../../../../../shire/types/Firebase/functions/MessageResult'
import { firebaseOnCall } from '../../../utils/firebaseCreateFunction'
import { doc, getDoc } from 'firebase/firestore'
import { simulateDelayLocal } from "../util/Utility";

export default class FirebaseAuthService implements IAuthService {
    ready: Promise<boolean>

    constructor () {
        this.ready = new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(
                firebaseAuth,
                user => {
                    // If the user does not have a web user then sign them out.
                    /*
                    if (!await this.checkForWebUsersAccount()) {
                        await signOut(firebaseAuth)
                    } */
                    unsubscribe()
                    resolve(true)
                },
                reject // pass up any errors attaching the listener
            )
        })
    }

    async createAccount (email: string, password: string): Promise<IResult> {
        try {
            await createUserWithEmailAndPassword(firebaseAuth, email, password)
            await simulateDelayLocal()
            
            // Additional tables setup for the user signed process handled by a Firebase cloud function.
            const userSignup = firebaseOnCall<{}, MessageResult>('userSignup')
            const result = await userSignup({})

            return {
                success: result.success,
                error: result.message,
            }
        } catch (error) {
            let errorMessage = 'Unknown error'
            if (typeof error === 'string') {
                errorMessage = error.toUpperCase()
            } else if (error instanceof FirebaseError) {
                if (error.code === 'auth/email-already-in-use') {
                    errorMessage = 'Email already in use'
                } else if (error.code === 'auth/invalid-email') {
                    errorMessage = 'Invalid email'
                } else if (error.code === 'auth/operation-not-allowed') {
                    errorMessage = 'Operation not allowed'
                } else if (error.code === 'auth/weak-password') {
                    errorMessage =
                        'Weak password make sure it is atleast 8 characters long'
                } else {
                    errorMessage = 'Unable to create user.'
                }
            }
            return {
                success: false,
                error: errorMessage,
            }
        }
    }

    async auth (email: string, password: string): Promise<IResult> {
        try {
            await signInWithEmailAndPassword(firebaseAuth, email, password)

            // check for the web_user account.
            /*
            if (!await this.checkForWebUsersAccount()) {
                await signOut(firebaseAuth)
                return {
                    success: false,
                    error: 'Unable to sign in. Error code: E52',
                }
            }*/

            return {
                success: true,
                error: '',
            }
        } catch (error) {
            let errorMessage = 'Unknown error'
            if (typeof error === 'string') {
                errorMessage = error.toUpperCase()
            } else if (error instanceof FirebaseError) {
                if (error.code === 'auth/invalid-email') {
                    errorMessage = 'Invalid email'
                } else if (error.code === 'auth/wrong-password') {
                    errorMessage = 'Wrong password'
                } else {
                    errorMessage =
                        'Unable to log in user. Check email and password.'
                }
            }

            return {
                success: false,
                error: errorMessage,
            }
        }
    }

    /* Later implementation if needed.
    async checkForWebUsersAccount() {
        if (firebaseAuth.currentUser == null) {
            throw new Error('User is not signed in')
        }
        const docRef = doc(
            firebaseDb,
            'web_users',
            firebaseAuth.currentUser.uid)
        const docSnap = await getDoc(docRef)
        return docSnap.exists()
    }*/

    async isAuth () {
        await this.ready
        return firebaseAuth.currentUser !== null
    }

    signout () {
        signOut(firebaseAuth)
    }
}
