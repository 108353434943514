import { Box, Grid } from '@mui/material'
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import lottieJson from '../../lottie/96085-green-check.json'
import CheckIn from './CheckIn'
import Diamond from './SuccessDiamond'

interface IRewardStackProps {
  rewardVisitCardId: string
  page: number
  windowDimensions: { width: number; height: number }
  visitsRequired: number
  visitedCount: number
  latestVisitAnimate: Boolean
}


export default function RewardStack (props: IRewardStackProps) {
  const { windowDimensions } = props
  const getWH = () => {
    if (windowDimensions.width <= 600) return '24vw'
    else if (windowDimensions.width <= 900) return '12.5vw'
    else return '11.5vw'
  }

  const lottieWH: string = getWH()
  const rows: any[] = []

  for (var i = 0; i < props.visitsRequired; i++) {
    let checked = false
    if (i < props.visitedCount) {
      checked = true
    }
    if (props.latestVisitAnimate && i + 1 == props.visitedCount) {
      rows.push(<AnimatedVisit windowDimensions={props.windowDimensions} />)
    } else {
      rows.push(<Visit checked={checked} />)
    }
  }

  let showDiamond = false
  if (props.visitsRequired == props.visitedCount) {
    showDiamond = true
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        pt: { xs: 3, sm: 0, md: 1 },
        px: 2
      }}
    >
      <Grid
        container
        alignItems='center'
        sx={{
          height: '100%'
        }}
        pb={{ xs: 4, sm: 1, md: 2 }}
        rowSpacing={{ xs: 3, sm: 1, md: 3 }}
      >
        {rows}
        {showDiamond && <Diamond enable={true} rewardVisitCardId={props.rewardVisitCardId}/>}
      </Grid>
    </Box>
  )
}

interface IVisitProps {
  checked: boolean
}

function Visit (props: IVisitProps) {
  return (
    <Grid
      item
      xs={3}
      md={3}
      sm={3}
      display='flex'
      justifyContent='center'
      height='100%'
    >
      <CheckIn checked={props.checked} />
    </Grid>
  )
}

interface IAnimatedVisitProps {
  windowDimensions: { width: number; height: number }
}

function AnimatedVisit (props: IAnimatedVisitProps) {
  const { windowDimensions } = props
  const getWH = () => {
    if (windowDimensions.width <= 600) return '24vw'
    else if (windowDimensions.width <= 900) return '12.5vw'
    else return '11.5vw'
  }

  const lottieWH: string = getWH()

  return (
    <Grid
      item
      xs={3}
      md={3}
      sm={3}
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{
        height: { xs: '22vw', sm: '9vw', md: '9vw', paddingBottom: '5px' }
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{
          width: lottieWH,
          height: lottieWH,
          display: 'flex',
          alignItems: 'center'
        }}
      />
    </Grid>
  )
}
