import * as React from "react";
import Box from "@mui/material/Box";
import Clear from "@mui/icons-material/Clear";
import Check from "@mui/icons-material/Check";

export interface ICheckIn {
  checked: boolean;
}

export default function CheckIn(props: ICheckIn) {
  const checked = (
    <Check
      sx={{
        width: { xs: "14vw", sm: "8vw", md: "7vw" },
        height: { xs: "14vw", sm: "8vw", md: "7vw" },
      }}
    />
  );
  const clear = (
    <Clear
      sx={{
        width: { xs: "14vw", sm: "8vw", md: "7vw" },
        height: { xs: "14vw", sm: "8vw", md: "7vw" },
        color: "white",
      }}
    />
  );

  return (
    <Box
      sx={{
        borderRadius: "100px",
        width: { xs: "17vw", sm: "9vw", md: "8vw" },
        height: { xs: "17vw", sm: "9vw", md: "8vw" },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "primary.dark",
        "&:hover": {
          backgroundColor: "primary.main",
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    >
      {props.checked ? checked : clear}
    </Box>
  );
}
