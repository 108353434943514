import { Box, Container, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useGeolocation from 'react-navigator-geolocation'
import { authService, loyaltyService } from '../di/diOmega'
import Loading from '../components/Loading'

// TODO -- What if the user is not on happy path for geolocation information. --

// 1.) Verify all parameters exist if not then redirect to login page
// 2.) If user is not logged in then redirect create account page (this page needs a link to the login page)
// 3.) Get geolocation information
// Run FB visit reward card
// Redirect to loyaltyrewards page

export default function Page () {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [lat, setLat] = useState(0)
    const [lng, setLng] = useState(0)
    const [status, setStatus] = useState('')

    // Get parameter id
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const clientId = urlParams.get('cid')
    const clientLocationId = urlParams.get('lid')

    const override = urlParams.get('override')
    const latOverride = urlParams.get('lat')
    const longOverride = urlParams.get('long')

    useEffect(() => {
        // 3.) Get geolocation information
        getLocation()
    }, [])

    // 1.) Verify all parameters exist if not then redirect to login page
    if (clientLocationId === null || clientId == null) {
        navigate(`/login`)
    }

    // 2.) If user is not logged in then redirect create account page (this page needs a link to the login page)
    if (!authService.isAuth()) {
        navigate(
            `/login?cid=${encodeURIComponent(
                clientId!
            )}&lid=${encodeURIComponent(clientLocationId!)}`
        )
    }

    const processLocation = async (latitude: number, longitude: number) => {
        let lat = latitude
        let long = longitude

        // DEBUG CODE //
        if (
            override === 'roboverride7' &&
            latOverride != null &&
            longOverride != null
        ) {
            lat = parseFloat(latOverride)
            long = parseFloat(longOverride)
        }

        const visited = await loyaltyService.visitRewardCard(
            clientId!,
            clientLocationId!,
            lat,
            long
        )
        if (visited.success) {
            navigate('/loyaltyrewards')
        } else {
            setStatus(visited.message)
            setLoading(false)
        }
    }

    const getLocation = () => {
        if (!navigator.geolocation) {
            setStatus('Geolocation is not supported by your browser')
            setLoading(false)
        } else {
            setStatus('Locating...')
            navigator.geolocation.getCurrentPosition(
                position => {
                    setStatus('')
                    processLocation(
                        position.coords.latitude,
                        position.coords.longitude
                    )
                    setLat(position.coords.latitude)
                    setLng(position.coords.longitude)
                },
                () => {
                    setStatus('Unable to retrieve your location')
                    setLoading(false)
                },
                { timeout: 30000 }
            )
        }
    }

    return (
        <Loading loading={loading}>
            <Grid
                container
                spacing={0}
                direction='column'
                alignItems='center'
                justifyContent='center'
                sx={{ minHeight: '100vh' }}
            >
                <Grid item xs={3}>
                    <Typography variant='h5' component='h5'>
                        {status}
                    </Typography>{' '}
                </Grid>
            </Grid>
        </Loading>
    )
}
