import { Typography } from "@mui/material"

interface INoInProgressProps {
    children: JSX.Element | JSX.Element[]
    nothing: boolean
  }
  
export default function NoInProgress(props: INoInProgressProps) {
    return (
        <>
        {props.nothing && (
            <Typography variant='h4'>
                No rewards in-progress go visit some businesses and get those visits.
            </Typography>
        )}
        {!props.nothing && props.children}
        </>
    )
}