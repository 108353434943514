import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { authService } from '../di/diOmega'
import { isLoginPage } from '../utils/navigation'

interface IAuthProps {
    children: JSX.Element
}

export default function Auth (props: IAuthProps) {
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [canAccessRoute, setCanAccessRoute] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const auth = await authService.isAuth()
            const hasAccess = auth || isLoginPage(location)
            setCanAccessRoute(hasAccess)
            setLoading(false)
        }
        fetchData()
    }, [])

    return loading ? <></> : canAccessRoute ? props.children : <Navigate to='/login' replace />
}
